<!--
 * @Description: 首页组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
  <div  id="home" name="home" :class="this.$root.apiGlobalConfigData['CondoleStyle']?'home condole-style':'home'">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="460px">
        <el-carousel-item v-for="item in carousel" :key="item.Id">
          <a :href="item.LinkHref">
            <img
              style="height: 460px"
              :src="item.ImagePath"
              :alt="item.ShortTitle"
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图END -->
    <div class="main-box" v-loading="loading">
      <div class="main">
        <div
          class="appliance"
          id="promo-menu"
          v-for="(item, index) in list"
          :key="index"
          :style="`color:${item.Color}`"
        >
          <div class="box-hd">
            <div class="home-title">{{ item.Name }}</div>
            <div class="more" id="more" @click="toGoods(0)">
              <!-- <MyMenu :val="item" @fromChild="getChildMsg">
                <span
                  v-for="(child, indexs) in item.TwoTypes"
                  :key="indexs"
                  :slot="child.Id"
                  >{{ child.Name }}</span
                >
              </MyMenu> -->
              <span>更多>></span>
            </div>
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <!-- <ul>
                <li>
                  <img
                    :src="'http://106.15.179.105:3000/public/imgs/appliance/appliance-promo1.png'"
                  />
                </li>
                <li>
                  <img
                    :src="'http://106.15.179.105:3000/public/imgs/appliance/appliance-promo2.png'"
                  />
                </li>
              </ul> -->
              <div class="banner">
                <!-- 'http://www.isgo.com/assets/images/daily-science.jpg' -->
                <img
                  :src="item.Image"
                />
                <div class="nav-box">
                  <ul>
                    <li
                      class="nav-box-li"
                      v-for="(item, index) in item.TwoTypes"
                      :key="index"
                      @click="toGoods(item.Id)"
                    >
                      <span>{{ item.Name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="list" v-if="item.TwoTypes.length > 0">
              <MyList
                :list="item.Goods"
                :ref="'list' + item.Id"
                
              ></MyList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carousel: [], // 轮播图数据
      list: [],
      loading: true,
      catchKey: "cashHomeData",
      goodList: [],
      classID: 0,
      categoryList:[],
      noticeContentText:""
    };
  },
  watch: {
    // list(e) {
    //   this.getGoodList = [];
    //   for (let item of e) {
    //     if (item.TwoTypes.length > 0) {
    //       let Goods = item.TwoTypes[0].Goods;
    //       this.goodList.push(Goods);
    //     }
    //   }
    // },
    noticeContentText(e){
      if(e){
         this.$alert(e, "尊敬的用户您好：", {});
      }
    }
  },
  computed: {},
  activated(){
    //  this.$alert("新平台已上线，此时正在上货中，稍候为您开放。谢谢您的理解和支持。", "尊敬的用户您好：", {});
    var host= window.location.host
    //console.log(host)
    if(host.indexOf(this.$host) <0 && host.indexOf(this.$icphost) <0){
      this.$alert(`当前为测试网站，为了不影响使用，请您移步至https://${this.$host}`, "尊敬的用户您好：",{callback:(action)=>{
        if (action == "confirm"){
         window.open('https://'+this.$host)
        }
      }});
    }
    this.getNotice();
  },
  async created() {
    const date = Date.parse(new Date());
    const cashData = localStorage.getItem(this.catchKey);
    let isGet = true;
    if (cashData) {
      // if (cashData - date > 0) {
      //   isGet = false;
      // }
    }
    if (isGet) {
      // 获取轮播图数据
      this.getImage();
      await this.getOneType();
      if (this.list) {
        localStorage.setItem(this.catchKey, date + 5 * 1000);
      }
    }
  },
  methods: {
    // 获取家电模块子组件传过来的数据
    getChildMsg(val, id) {
      // let refs = this.$refs["list" + id];
      // refs[0].onChange(val.Goods, val.Id);
    },
    getGoodList(val, index) {
      this.goodList.push(val.Goods);
    },
    //获取一级分类
    async getOneType() {
      this.loading = true;

      await this.$axios.get("/api/VouchersGoods/GetIndexGoods").then((res) => {
        this.list = res.data;
        this.loading = false;
      });
    },
    async getImage() {
      // 获取轮播图数据
      await this.$axios
        .get("/api/LanternSlide/GetIndexLanternSlide", "")
        .then((res) => {
          this.carousel = res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    toGoods(id){
      this.$router.push({
          path: "/goods",
          query: { categoryID: id },
        });
    },
    getNotice () {
      this.noticeContentText=""
      // 获取轮播图数据
       this.$axios
        .get("/api/VouchersGoods/GetNotice", "")
        .then((res) => {
          this.noticeContentText = res.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
</script>
<style scoped>
@import "../assets/css/index.css";

</style>